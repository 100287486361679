@tailwind base;
@tailwind components;
.Dropdown {
    @apply absolute shadow-3xl w-[280px] bg-white top-[95%] left-[-100%] transition-all duration-1000 opacity-0 invisible origin-top z-50 rotate-x--90 group-hover:visible group-hover:opacity-100 group-hover:h-auto group-hover:rotate-x-0;
}
.subMenu {
    @apply px-4 py-3 leading-6 font-light border-b border-[#f1f1f1] hover:text-[#03880e];
}
.headerBtn {
    @apply text-[#03880e] border border-[#03880e] rounded-lg font-semibold overflow-hidden px-4 py-[10px] mr-[10px] relative before:content-[''] before:w-full before:h-full before:bg-[#03880e] before:absolute before:scale-0 before:transition-all before:top-0 before:left-0 before:ease-in-out hover:before:scale-100;
}
.headerSolidBtn {
    @apply bg-[#03880e] border border-[#03880e] overflow-hidden text-white rounded-lg font-semibold px-4 py-[10px] relative before:content-[''] before:w-full before:h-full before:bg-white before:absolute before:scale-0 before:transition-all before:top-0 before:left-0 before:ease-in-out hover:before:scale-100;
}
.solidBtn {
    @apply bg-[#03880e] border border-[#03880e] text-white rounded-lg font-light px-4 py-[10px] transition-all hover:bg-white hover:border-[#03880e] hover:text-[#03880e];
}
.heading {
    @apply font-bold text-3xl text-gray-800 xl:text-5xl;
}

@tailwind utilities;

@layer utilities {
    .rounded {
        border-radius: 100% !important;
    }
    .text-5xl {
        font-size: 3rem !important;
        line-height: 4rem !important;
    }
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: "Poppins", sans-serif;
    color: #4A4A4A;
}

.accordion__heading [aria-expanded="true"] svg {
    transform: rotate(180deg);
    color: #03880e;
}